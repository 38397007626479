<script setup lang="ts">
// Properties and events.
//
const props = defineProps<{
  styleOpts?: string
  tableHover?: boolean
  tableSortable?: boolean
  resultCount?: number
  loadingState?: boolean
  overflowVisible?: boolean
  customResultMessage?: string
  containerStyles?: string
  plainContainer?: boolean
  // ^ Enable to prevent the table reformatting on portrait views
}>()
// Main variables.
//
const styleOpts = toRef(props, 'styleOpts')
const tableHover = toRef(props, 'tableHover')
const tableSortable = toRef(props, 'tableSortable')
const resultCount = toRef(props, 'resultCount')
const loadingState = toRef(props, 'loadingState')
const overflowVisible = toRef(props, 'overflowVisible')
const containerStyles = toRef(props, 'containerStyles')
const customResultMessage = toRef(props, 'customResultMessage')
const hasHover = tableHover.value ? tableHover.value : ref(false)
const isSortable = tableSortable.value ? tableSortable.value : ref(false)
const plainContainer = toRef(props, 'plainContainer')
</script>

<template>
  <div
    :class="[
      containerStyles,
      {
        'overflow-visible': overflowVisible,
        'rk-table': !plainContainer,
        'table-reformat': !plainContainer,
      },
    ]"
  >
    <table
      class="table"
      :class="[
        styleOpts,
        {
          'table-hover': hasHover,
          'table-sort': isSortable,
          'table-reformat': !plainContainer,
        },
      ]"
    >
      <slot ref="child"></slot>
    </table>
  </div>
  <div
    v-if="!loadingState && resultCount === 0"
    class="rk-alert alert alert-info mb-3"
    role="alert"
  >
    <span class="text-center">
      <b v-if="customResultMessage">
        {{ customResultMessage }}
      </b>
      <b v-else> No results were found </b>
    </span>
  </div>
</template>

<style lang="scss"></style>
