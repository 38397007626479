<script setup lang="ts">
// Properties and events.
//
const props = defineProps<{
  stickyTitle: string
}>()
// Main variables.
//
const stickyTitle = toRef(props, 'stickyTitle')
</script>

<template>
  <tbody>
    <tr class="sticky">
      <td class="d-block text-center">
        <span class="rk-capitalize rk-semi-bold m-1"> {{ stickyTitle }}: </span>
      </td>
    </tr>
    <slot ref="child"></slot>
  </tbody>
</template>

<style lang="scss"></style>
