<script setup lang="ts">
// Properties and events.
//
const emit = defineEmits(['process:sort'])
const props = defineProps<{
  name?: string
  styleOpts?: string
  isSortable?: boolean
  sortTerm?: string
  currentTerm?: string
  currentDirection?: string
  defaultDirection?: string
}>()
// Main variables.
//
const styleOpts = toRef(props, 'styleOpts')
const name = toRef(props, 'name')
const isSortable = toRef(props, 'isSortable')
const sortTerm = toRef(props, 'sortTerm')
const currentTerm = toRef(props, 'currentTerm')
const currentDirection = toRef(props, 'currentDirection')
const defaultDirection: any = toRef(props, 'defaultDirection')
const direction = defaultDirection.value === 'DESC' ? ref(true) : ref(false)
// Functions.
//
const processClick = function processClick() {
  let ascDesc = ''
  if (currentTerm.value === sortTerm.value) {
    direction.value = !direction.value
    ascDesc = direction.value ? 'DESC' : 'ASC'
  } else {
    direction.value = false
    ascDesc = defaultDirection.value
  }
  // Emit sort options.
  //
  emit('process:sort', {
    term: sortTerm.value,
    direction: ascDesc,
  })
}

onMounted(() => {
  direction.value = currentDirection.value === 'DESC'
})
</script>

<template>
  <th
    v-if="isSortable"
    class="sort"
    :class="[styleOpts, { active: currentTerm === sortTerm }]"
    @click="processClick"
  >
    <span v-if="name">{{ name }}</span>
    <i class="rk-icon rk-icon--text-xs fontello icon-sys-dropdown"></i>
  </th>
  <th v-else :class="[styleOpts]">
    <span v-if="name">{{ name }}</span>
  </th>
</template>

<style lang="scss"></style>
