<script setup lang="ts">
// Properties and events.
//
const props = defineProps<{
  isSelectable?: boolean
  isInteractive?: boolean
}>()
// Main variables.
//
const isSelectable = toRef(props, 'isSelectable')
const isInteractive = toRef(props, 'isInteractive')
</script>

<template>
  <thead>
    <tr>
      <th v-if="isSelectable"></th>
      <slot ref="child"></slot>
      <th v-if="isInteractive"></th>
    </tr>
  </thead>
</template>

<style lang="scss"></style>
